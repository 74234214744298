<template>
  <div
    class="row justify-center bg-grey-3 items-start"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card style="width:300px; ">
      <q-card-section class="row justify-between q-pb-none">
        <p class="text-h6">
          <strong>Prestasi :</strong>
        </p>
        <q-btn
          v-show="isEdit"
          flat
          icon="delete_forever"
          color="red"
          @click="dialogDelete = true"
        ></q-btn>
      </q-card-section>
      <q-card-section class="q-gutter-md">
        <q-select
          outlined
          stack-label
          label="Siswa"
          :options="siswa"
          :readonly="parseInt($route.params.id_prestasi) > 0"
          v-model="bundlePrestasi.id_siswa"
        ></q-select>
        <q-input
          outlined
          stack-label
          bottom-slots
          label="Nama Prestasi"
          v-model="bundlePrestasi.nama"
        >
          <template v-slot:hint
            >Ex: Juara 1 Lomba Lari, Meraih Penghargaan</template
          >
        </q-input>
        <q-input
          outlined
          stack-label
          bottom-slots
          label="Bidang"
          v-model="bundlePrestasi.bidang"
        >
          <template v-slot:hint>Ex: Olah Raga, Kesenian, dsb.</template>
        </q-input>
        <q-input
          outlined
          stack-label
          label="Penyelenggara"
          v-model="bundlePrestasi.penyelenggara"
        ></q-input>
        <q-input
          outlined
          stack-label
          label="Tingkat"
          v-model="bundlePrestasi.tingkat"
        ></q-input>
        <q-input
          outlined
          stack-label
          bottom-slots
          label="Kategori"
          v-model="bundlePrestasi.kategori"
        >
          <template v-slot:hint>Ex: Perorangan, Kelompok</template>
        </q-input>
      </q-card-section>

      <q-card-actions>
        <q-btn
          flat
          class="full-width"
          color="primary"
          label="back"
          @click="
            $router.push(
              '/penilaianlistprestasi/' +
                $route.params.id_kelas +
                '/' +
                $route.params.id_siswa
            )
          "
        ></q-btn>
        <q-btn
          unelevated
          v-if="parseInt($route.params.id_prestasi) < 0"
          class="full-width no border-radius"
          color="primary"
          label="Tambah"
          @click="addPrestasi"
        ></q-btn>
        <q-btn
          unelevated
          v-else
          class="full-width no-border-radius"
          color="primary"
          label="Edit"
          @click="editPrestasi"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="dialogDelete">
      <q-card class="bg-warning">
        <q-card-section
          >Apa Anda yakin ingin menghapus Prestasi ini?</q-card-section
        >
        <q-card-actions align="right">
          <q-btn flat label="Tidak" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="ya"
            text-color="white"
            color="negative"
            @click="deletePrestasi"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogListPrestasi from "@/components/DialogListPrestasi";
export default {
  data() {
    return {
      isEdit: true,
      dialogDelete: false,
      siswa: [],
      bundlePrestasi: {
        id_siswa: null,
        nama: "",
        bidang: "",
        penyelenggara: "",
        tingkat: "",
        kategori: "",
      },
    };
  },
  mounted() {
    if (this.$route.params.id_prestasi < 0) {
      this.isEdit = false;
      this.getListSiswa();
    } else {
      this.isEdit = true;
      this.getPrestasiDetail();
    }
  },
  methods: {
    showDialogPrestasi() {
      this.$q.dialog({
        component: DialogListPrestasi,
        parent: this,
        siswa: this.bundlePrestasi.id_siswa,
      });
    },
    async getPrestasiDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/prestasi/input/getdetail/${this.$route.params.id_prestasi}`
      );
      resp.data.id_siswa = {
        label: resp.data.nama_siswa,
        value: resp.data.id_siswa,
      };
      this.bundlePrestasi = resp.data;
      this.$q.loading.hide();
    },
    async getListSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        "/penilaian/riwayat_absen/getsiswa/" + this.$route.params.id_kelas
      );
      this.siswa = resp.data;
      if (parseInt(this.$route.params.id_siswa) != -1) {
        this.bundlePrestasi.id_siswa = this.siswa.find(
          (item) => item.value == this.$route.params.id_siswa
        );
      }
      this.$q.loading.hide();
    },
    addPrestasi() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.bundlePrestasi.id_siswa = this.bundlePrestasi.id_siswa.value;
      this.bundlePrestasi.id_tahun_ajaran = localStorage.getItem(
        "id_tahun_ajaran"
      );
      this.$http.post(`/prestasi/input/add`, this.bundlePrestasi);
      this.$q.notify({
        message: "Prestasi Berhasil Ditambahkan",
        color: "positive",
      });
      this.$q.loading.hide();
      this.$router.push(
        `/penilaianlistprestasi/${this.$route.params.id_kelas}/${this.$route.params.id_siswa}`
      );
    },
    editPrestasi() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.bundlePrestasi.id_siswa = this.bundlePrestasi.id_siswa.value;
      this.$http.put(`/prestasi/input/edit`, this.bundlePrestasi);
      this.$q.notify({
        message: "Prestasi Berhasil Diubah",
        color: "positive",
      });
      this.$q.loading.hide();
      this.$router.push(
        `/penilaianlistprestasi/${this.$route.params.id_kelas}/${this.$route.params.id_siswa}`
      );
    },
    deletePrestasi() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http.delete(
        `/prestasi/input/deletePrestasi/${this.$route.params.id_prestasi}`
      );
      this.$q.notify({
        message: "Prestasi Berhasil Dihapus",
        color: "negative",
      });
      this.$q.loading.hide();
      this.$router.push(
        `/penilaianlistprestasi/${this.$route.params.id_kelas}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
