<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin q-pa-md">
      <p class="row justify-between">
        <a>Nama</a>
        <a>: {{ siswa.label }}</a>
      </p>
      <q-list bordered class="rounded-borders">
        <q-item v-for="(val, i) in prestasi" :key="i" style="display: contents">
          <q-expansion-item
            expand-separator
            :label="val.nama"
            :caption="val.bidang"
          >
            <div class="q-ml-md">
              <q-item>
                <q-item-section>Tahun Ajaran</q-item-section>
                <q-item-section>: {{ val.tahun_ajaran }}</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Semester</q-item-section>
                <q-item-section>: {{ val.semester_ajaran }}</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Tingkat</q-item-section>
                <q-item-section>: {{ val.tingkat }}</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Kategori</q-item-section>
                <q-item-section>: {{ val.kategori }}</q-item-section>
              </q-item>
            </div>
          </q-expansion-item>
        </q-item>
      </q-list>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Close" @click="onCancelClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["siswa"],
  data() {
    return {
      prestasi: []
    };
  },
  async mounted() {
    this.getListPrestasi();
  },
  methods: {
    async getListPrestasi() {
      let resp = await this.$http.get(
        `/prestasi/list/getPrestasiAll/${this.siswa.value}`
      );
      this.prestasi = resp.data;
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      this.$emit("ok");
      this.hide();
    },
    onCancelClick() {
      this.hide();
    }
  }
};
</script>
